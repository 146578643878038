import { accessTokenStore } from "../../framework/stores/app-store";
import { CognitoService } from "../../services/cognito";
import { Hub } from "aws-amplify/utils";
import { initFlowbite } from "flowbite";
import { navigate } from "astro/virtual-modules/transitions-router.js";
import { useEffect, useState } from "react"
import { userPersonalDataStore, userRootDataStore } from "../../framework/stores/user-store";
import { useStore } from "@nanostores/react";
import type { UserPersonalDataType } from "../../types/user-types";
import { sendGet } from "../../services/httpService";

const Navbar = () => {

    const cognitoService = new CognitoService();

    const $userRootData = useStore(userRootDataStore);
    const $userPersonalData = useStore(userPersonalDataStore);
    const $accessToken = useStore(accessTokenStore);

    const [userId, setUserId] = useState();
    const [userEmail, setUserEmail] = useState("");
    const [theme, setTheme] = useState(localStorage.getItem("theme") ?? "light");

    const themeSelectorButtonOnClickHandler = () => {
        setTheme(theme === "light" ? "dark" : "light");
    };

    useEffect(() => {
        if (theme === "dark") {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
        localStorage.setItem("theme", theme);
        initFlowbite();

    });

    useEffect(() => {
        const unsubscribe = Hub.listen("auth", ({ payload }) => {
            switch (payload.event) {
                case "signInWithRedirect":
                    cognitoService.getCurrentSession().then((sessionData) => {
                        setUserData(sessionData);
                    });
                    break;
                case "signInWithRedirect_failure":
                    console.error("signInWithRedirect_failure:", payload);
                    break;
                case "signedOut":
                    clearUserData();
                    navigate("/");
                    break;
            }
        });
        return unsubscribe;
    }, []);

    const setUserData = (currentSessionData: any) => {
        const personalData = currentSessionData.tokens.idToken.payload["data:personal"] as UserPersonalDataType;
        if (personalData) {
            userPersonalDataStore.set(personalData);
        }
        else {
            userPersonalDataStore.set({
                fullName: currentSessionData.tokens.idToken.payload["name"],
                shortName: currentSessionData.tokens.idToken.payload["given_name"]
            });
        }

        setUserId(currentSessionData.tokens.idToken.payload["cognito:username"]);

        const accessToken = currentSessionData.tokens.accessToken.toString();
        accessTokenStore.set(accessToken);
        setUserEmail(currentSessionData.tokens.idToken.payload.email);
    };

    useEffect(() => {
        if (!$accessToken || !userId) return;
        
        sendGet(`${import.meta.env.PUBLIC_CI_ADMIN_API_URL}/v2/users/${userId}`)
            .then(response => response.json())
            .then(userRootData => {
                if (userRootData) {
                    userRootDataStore.set(userRootData);
                }
            });
    }, [$accessToken, userId])

    useEffect(() => {
        if ($userRootData?.id === undefined) {
            cognitoService.getCurrentSession()
                .then((sessionData) => {
                    if (!sessionData.tokens?.idToken) {
                        location.assign("/auth/sign-in")
                        return;
                    }
                    setUserData(sessionData);
                });
        }
    }, []);

    const clearUserData = () => {
        userRootDataStore.set(undefined);
        userPersonalDataStore.set(undefined);
    }

    return (
        <nav className="bg-white border-b border-gray-200 px-4 py-2.5 dark:bg-gray-900 dark:border-gray-700 fixed left-0 right-0 top-0 z-50">
            <div className="flex flex-wrap justify-between items-center">

                {/* LEFT-ITEMS */}
                <div className="flex justify-start items-center">
                    {/* Menu Button */}
                    <button
                        data-drawer-target="drawer-navigation"
                        data-drawer-toggle="drawer-navigation"
                        className="p-2 mr-2 text-gray-600 rounded-lg cursor-pointer md:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                        <svg
                            className="w-6 h-6"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                            ></path>
                        </svg>
                        <svg
                            className="hidden w-6 h-6"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path

                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"

                            ></path>
                        </svg>
                        <span className="sr-only">Toggle sidebar</span>
                    </button>

                    {/* Logo */}
                    <a href="/" className="flex items-center justify-between mr-4">
                        <img
                            src="/assets/imgs/logo-light.png"
                            className="mr-3 h-8 md:block dark:hidden"
                            alt="Softlight Logo"
                        />
                        <img
                            src="/assets/imgs/logo-dark.png"
                            className="mr-3 h-8 md:hidden hidden dark:block"
                            alt="Softlight Logo"
                        />
                    </a>
                </div>

                {/* RIGHT-ITEMS */}
                <div className="flex items-center lg:order-2">
                    {/* ThemSelector Button */}
                    <button type="button"
                        onClick={themeSelectorButtonOnClickHandler}
                        className="text-slate-700 hover:text-slate-400 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center  dark:text-slate-400 dark:hover:text-slate-200  ">
                        {theme === "dark" ?
                            <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor">
                                <path d="M480-360q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Zm0 80q-83 0-141.5-58.5T280-480q0-83 58.5-141.5T480-680q83 0 141.5 58.5T680-480q0 83-58.5 141.5T480-280ZM200-440H40v-80h160v80Zm720 0H760v-80h160v80ZM440-760v-160h80v160h-80Zm0 720v-160h80v160h-80ZM256-650l-101-97 57-59 96 100-52 56Zm492 496-97-101 53-55 101 97-57 59Zm-98-550 97-101 59 57-100 96-56-52ZM154-212l101-97 55 53-97 101-59-57Zm326-268Z" />
                            </svg>
                            :
                            <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor">
                                <path d="M480-120q-150 0-255-105T120-480q0-150 105-255t255-105q14 0 27.5 1t26.5 3q-41 29-65.5 75.5T444-660q0 90 63 153t153 63q55 0 101-24.5t75-65.5q2 13 3 26.5t1 27.5q0 150-105 255T480-120Zm0-80q88 0 158-48.5T740-375q-20 5-40 8t-40 3q-123 0-209.5-86.5T364-660q0-20 3-40t8-40q-78 32-126.5 102T200-480q0 116 82 198t198 82Zm-10-270Z" />
                            </svg>

                        }
                        <span className="sr-only">Icon description</span>
                    </button>
                    {/* UserMenu Button */}
                    <button
                        className="flex mx-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                        data-dropdown-toggle="dropdown-user-menu"
                    >
                        <img
                            className="w-8 h-8 rounded-full"
                            src={$userRootData?.picture}
                            alt="user photo"
                        />
                    </button>
                    {/* <!-- UserMenu Dropdown menu --> */}
                    <div
                        className="hidden z-50 my-4 w-56 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600"
                        id="dropdown-user-menu"
                    >
                        <div className="py-3 px-4">
                            <span
                                className="block text-sm font-semibold text-gray-900 dark:text-white"
                            >
                                {$userPersonalData?.fullName}
                            </span>
                            <span
                                className="block text-sm text-gray-900 truncate dark:text-white"
                            >{userEmail}</span>
                        </div>
                        <ul
                            className="py-1 text-gray-700 dark:text-gray-300"
                        >
                            <li>
                                <a
                                    href={`/users/${userId}`}
                                    className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white"
                                >Mi perfil</a>
                            </li>
                        </ul>
                        <ul
                            className="py-1 text-gray-700 dark:text-gray-300"
                        >
                            <li>
                                <a
                                    href="#"
                                    onClick={cognitoService.signOut}
                                    className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                >Sign out</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar